.gm-style-iw,
.gm-style-iw-d,
.gm-style .gm-style-iw-t::after{
    overflow: auto !important;
    background: none !important;
    box-shadow: none !important;
    padding: 0 !important;
    margin: 0 !important;
}
.gm-style .gm-style-iw-t::after {
    display: none;
}
.gm-style-iw > button{
    display: none !important;
}
.poi-info-window {
    padding: 10px;
    background: #FFF;
}