.pac-container {
    font-family: CircularStd,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    z-index: 1500;
}
.pac-container:after {
    display: none;
}
.pac-icon {
    width: 15px;
}
.pac-item {
    font-size: 16px;
    padding: 10px;
}
.pac-item:hover {
    cursor: pointer;
}
.pac-item-selected {
}
.pac-item-query {
    font-size: 16px;
}
/*
.pac-item-query:after {
    content: '';
    display: block;
}
*/
.pac-matched {
    font-size: 16px;
}